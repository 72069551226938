<template>
    <v-card
        color="transparent"
        outlined
    >
        <v-card-title>Frågeformulär</v-card-title>
        <v-card-subtitle>
            Tester och undersökningar
        </v-card-subtitle>
        <v-data-table
            :items="questions"
            :loading="loading"
            :headers="headers"
            :header-props="header_props"
            :server-items-length="total"
            :options.sync="options"
            @click:row="show_play_sequence_dialog"
            loading-text="Laddar..."
            hide-default-footer
        >
            <template #item._created="{ item }">
                {{ format_date(item['_created']) }}
            </template>
            <template #item.score="{ item }">
                <div v-if="item.survey_type!=='exam'">
                    -
                </div>
                <div v-else>
                    {{ item.score }}
                </div>
            </template>
            <template #item.grade="{ item }">
                <div v-if="item.survey_type!=='exam'">
                    -
                </div>
                <div v-else-if="item.grade==='pass_with_excellence'">
                    MVG
                </div>
                <div v-else-if="item.grade==='pass'">
                    Godkänd
                </div>
                <div v-else-if="item.grade==='failed'">
                    Underkänd
                </div>
                <div v-else>

                </div>
            </template>
            <template #footer>
                <v-divider/>
                <v-card-subtitle class="d-flex align-center justify-end">
                    <v-btn
                        @click="options.page -= 1"
                        :disabled="options.page === 1"
                        icon
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    {{ options.page }} / {{ pages }}
                    <v-btn
                        @click="options.page += 1"
                        :disabled="options.page === pages"
                        icon
                    >
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-card-subtitle>
            </template>
        </v-data-table>
        <QuestionnaireAnswers
            v-model="show_dialog"
            :play_sequence="play_sequence"
            :key="show_dialog"
        />
    </v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {api_mixin} from '@/mixins/services/api_mixin'
import {date_mixin} from '@/mixins/services/date_mixin'
import QuestionnaireAnswers from '@/components/user/QuestionnaireAnswers.vue'

export default {
    name: 'Questionnaire',
    props: {
        user_id: {
            type: String,
        },
    },
    mixins: [api_mixin, date_mixin],
    components: {QuestionnaireAnswers},
    data() {
        return {
            loading: true,
            questions: [],
            total: -1,
            header_props: {
                sortByText: 'Sortera',
            },
            headers: [
                {
                    text: 'Datum',
                    value: '_created',
                },
                {
                    text: 'Namn',
                    value: 'survey_info',
                },
                {
                    text: 'Modul',
                    value: 'survey_module_info',
                },
                {
                    text: 'Poäng',
                    value: 'final_score',
                },
                {
                    text: 'Nivå',
                    value: 'grade',
                    sortable: false,
                    align: 'center',
                },
            ],
            options: {
                sortBy: ['-_created'],
                sortDesc: [false],
                itemsPerPage: 20,
                page: 1,
                mustSort: true,
            },
            play_sequence: [],
            show_dialog: false,
        }
    },
    computed: {
        pages() {
            const pages = Math.ceil(this.total / this.options.itemsPerPage)
            return pages <= 0 ? 1 : pages
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        options: {
            handler() {
                this.load_data()
            },
            deep: true,
        },
    },
    methods: {
        async load_data() {
            this.loading = true
            const [sort_by] = this.options.sortBy
            const [sort_desc] = this.options.sortDesc
            const sort = sort_desc ? '-' + sort_by : '' + sort_by
            const max_results = this.options.itemsPerPage
            const page = this.options.page
            const result = await this.api_get({
                url: 'x-u2work--survey-final-scores',
                params: {
                    where: {
                        user: this.user_id,
                    },
                    projection: {
                        survey_info:1,
                        survey_module_info: 1,
                        survey_type: 1,
                        final_score: 1,
                        grade: 1,
                        answers: 1,
                    },
                    sort: sort,
                    max_results: max_results,
                    page: page,
                },
            })
            this.questions = result.data['_items']
            this.total = result.data['_meta']['total']
            this.loading = false
        },
        async show_play_sequence_dialog(data) {
            console.info(data)
            this.play_sequence = data.answers
            this.show_dialog = true
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
        this.load_data()
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>

<style scoped lang="sass">
::v-deep tbody tr:hover
    cursor: pointer
</style>
