<template>
    <v-dialog
        v-model="internal_value"
        max-width="700"
        scrollable
    >
        <v-card>
            <v-card-title>Frågor & svar</v-card-title>
            <v-divider/>
            <v-card-text
                class="pb-0"
                style="height: 400px; background-color: #eee"
            >
                <v-timeline dense>
                    <v-timeline-item
                        v-for="(item, index) in play_sequence"
                        :key="index"
                        :color="item['answer_is_correct'] ? 'success':'error'"
                        :icon="item['answer_is_correct'] ? 'fa-thumbs-up':'fa-thumbs-down'"
                        icon-color="#333"
                        fill-dot
                        large
                    >
                        <v-card>
                            <v-card-subtitle class="font-weight-bold">
                                {{ item['answer_survey_question_sort_order'] }}.
                                {{ item['answer_survey_question_info'] }}
                            </v-card-subtitle>
                            <v-card-text>
                                {{ item['answer_info'] }}
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    @click="internal_value = false"
                    color="primary"
                >
                    Stäng
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
    name: 'QuestionnaireAnswers',
    props: {
        value: {
            type: Boolean,
        },
        play_sequence: {
            type: Array,
            default: () => [],
        },
    },
    mixins: [],
    components: {},
    data() {
        return {}
    },
    computed: {
        internal_value: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>

<style scoped lang="sass"></style>
